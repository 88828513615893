import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`
  font-size: 36px;
  color: #80CC28; // Green from the logo for titles  
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 28px;
  margin-top: 30px;
  margin-bottom: 10px;
  color: #80CC28; // #FFA500   A vibrant orange-red for contrast   #FFA706
`;

const Paragraph = styled.p`
  font-size: 18px;
  color: #5E2590;
  line-height: 1.4;
  margin-bottom: 20px;
  font-style: italic;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  color: #5E2590;
  font-size: 18px;

  li {
    margin-bottom: 5px;

    //&::marker {
    //  color: #FBC707; // Color for the bullets
    //}
  }
`;

const OrderedList = styled.ol`
  padding-left: 20px;
  color: #5E2590; 
  font-size: 18px;

  li {
    margin-bottom: 5px;

    //&::marker {
    //  color: #FBC707; // Color for the numbers
    //}
  }
`;

const PageContainer = styled.div`
  padding: 60px 80px;
  justify-content: space-between;
  align-items: flex-start;
  background: white;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  overflow-y: auto;
  color: #5E2590; 
  height: calc(100vh - 60px);
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
    padding: 30px 20px;
    margin-bottom: 195px;
  }
`;

const RecipeComponent = ({ recipe }) => (
    <PageContainer>
        <Title>{recipe.name}</Title>
        {recipe.story && (
            <>
                <Paragraph>{recipe.story}</Paragraph>
            </>
        )}
        <SectionTitle>Ingredients</SectionTitle>
        <List>
            {recipe.ingredients.map((ingredient, index) => (
                <li key={index}>{ingredient}</li>
            ))}
        </List>
        <SectionTitle>Instructions</SectionTitle>
        <OrderedList>
            {recipe.instructions.map((instruction, index) => (
                <li key={index}>{instruction}</li>
            ))}
        </OrderedList>
        {recipe.notes && (
            <>
                <SectionTitle>Notes</SectionTitle>
                <List>
                    {recipe.notes.map((note, index) => (
                        <li key={index}>{note}</li>
                    ))}
                </List>
            </>
        )}
        {recipe.brands && (
            <>
                <SectionTitle>Recommended Brands</SectionTitle>
                <List>
                    {recipe.brands.map((brand, index) => (
                        <li key={index}>{brand}</li>
                    ))}
                </List>
            </>
        )}
        {recipe.products && (
            <>
                <SectionTitle>Products I Like</SectionTitle>
                <List>
                    {recipe.products.map((product, index) => (
                        <li key={index}>{product}</li>
                    ))}
                </List>
            </>
        )}
        {recipe.subRecipe && (
            <>
                <SectionTitle>{recipe.subRecipe.name}</SectionTitle>
                <h3>Ingredients</h3>
                <List>
                    {recipe.subRecipe.ingredients.map((ingredient, index) => (
                        <li key={index}>{ingredient}</li>
                    ))}
                </List>
                <h3>Instructions</h3>
                <OrderedList>
                    {recipe.subRecipe.instructions.map((instruction, index) => (
                        <li key={index}>{instruction}</li>
                    ))}
                </OrderedList>
            </>
        )}
    </PageContainer>
);

export default RecipeComponent;
