const thursdayDinners = [
    {
        number: 1,
        name: "Polenta or Barley and Roasted Veggies",
        recipeId: "polenta-barley-roasted-veggies",
        ingredients: [
            "3 tbsp olive oil",
            "1 tsp Italian seasoning",
            "1/2 tsp garlic powder",
            "1/2 tsp smoked paprika",
            "1/4 tsp crushed red pepper flakes (optional, for heat)",
            "1/2 tsp salt",
            "1 cup zucchini, cut into 1-inch chunks",
            "1 cup summer squash, cut into 1-inch chunks",
            "1 cup cherry tomatoes",
            "1 cup asparagus, cut into 1-inch chunks",
            "1 cup mushrooms, halved",
            "For the Parmesan Polenta:",
            "3 cups water",
            "1 cup milk (or plant-based milk)",
            "1 cup coarse or medium-grit cornmeal",
            "3 tbsp butter, divided",
            "1/2 cup grated Parmesan cheese (or nutritional yeast for vegan option)",
            "1 1/2 tsp salt",
            "1/2 tsp garlic powder",
            "For the Barley Option:",
            "1 cup pearl barley",
            "3 cups vegetable broth or water",
            "1 bay leaf (optional)"
        ],
        instructions: [
            "Preheat the oven to 425°F.",
            "Place the zucchini, summer squash, cherry tomatoes, asparagus, and mushrooms on a parchment-lined baking sheet.",
            "In a small bowl, mix olive oil, Italian seasoning, garlic powder, smoked paprika, crushed red pepper flakes (if using), and salt. Drizzle the mixture over the vegetables and toss to coat evenly.",
            "Roast the vegetables for 20-25 minutes, stirring halfway through, until tender and slightly caramelized.",
            "For the Parmesan Polenta: In a heavy-bottomed pan or Dutch oven, bring the water and milk to a boil. Gradually whisk in the cornmeal, reduce heat to a simmer, and cover. Cook for 25-30 minutes, stirring occasionally to prevent sticking or burning.",
            "Once the polenta is thick and cooked, remove from heat. Stir in Parmesan cheese, 2 tbsp butter, garlic powder, and salt until well combined. Adjust seasoning to taste.",
            "For the Barley Option: Rinse the barley under cold water. In a saucepan, combine barley, vegetable broth (or water), and bay leaf. Bring to a boil, then reduce heat to a simmer. Cover and cook for 25-30 minutes, or until tender and liquid is absorbed. Discard the bay leaf and fluff the barley with a fork.",
            "To serve: Place the polenta or barley in the bottom of a bowl. Top with roasted vegetables and garnish with a drizzle of olive oil, balsamic vinegar, or fresh herbs like parsley or basil."
        ],
        notes: [
            "For a vegan option, replace Parmesan with nutritional yeast and butter with vegan butter.",
            "Add more flavor by sprinkling the roasted vegetables with lemon zest or a squeeze of fresh lemon juice before serving.",
            "For a heartier version, add roasted chickpeas or white beans to the vegetables."
        ]
    },
    {
        number: 2,
        name: "Spaghetti with Lentilballs and Side Italian Salad",
        recipeId: "spaghetti-lentilballs-italian-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 3,
        name: "Angel Hair Pesto with Tomatoes on the Side",
        recipeId: "angel-hair-pesto-tomatoes",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 4,
        name: "French Onion Soup with Roasted Vegetable Salad",
        recipeId: "french-onion-soup-vegetable-salad",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 5,
        name: "Pizza",
        recipeId: "pizza",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 6,
        name: "Lasagna",
        recipeId: "lasagna",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 7,
        name: "TVP Bolognese",
        recipeId: "tvp-bolognese",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 8,
        name: "French Lentils with Provençal New Potatoes",
        recipeId: "french-lentils-provencal-potatoes",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 9,
        name: "Butternut Squash Pitta Pizza",
        recipeId: "butternut-squash-pitta-pizza",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 10,
        name: "Mushroom Risotto",
        recipeId: "mushroom-risotto",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 11,
        name: "Vegan Schnitzel with Spargle and Potatoes",
        recipeId: "vegan-schnitzel-spargle-potatoes",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    },
    {
        number: 12,
        name: "Tuscan White Bean Stew",
        recipeId: "tuscan-white-bean-stew",
        ingredients: ["TBD"],
        instructions: ["TBD"],
        notes: ["TBD"]
    }
];

export default thursdayDinners;
