import styled from 'styled-components';

const HomePageContainer = styled.div`
  padding: 20px 40px;
  background: white; /*linear-gradient(135deg, #FFDFAF, #FFF7E8);*/ /* Light gradient to match branding */
  text-align: center;
  font-family: 'Arial', sans-serif;
  color: #4A4A4A;
  min-height: 100vh; /* Ensure full-page height */
  display: flex;
  flex-direction: column;
/*  justify-content: center;*/
  align-items: center;
`;

const Title = styled.h1`
  font-size: 48px;
  color: #80CC28; /* Bright green to align with branding */
  margin-bottom: 20px;
`;

const Subtitle = styled.h2`
  font-size: 28px;
  color: #4A4A4A;
  margin-bottom: 40px;
`;

const CallToAction = styled.p`
  font-size: 20px;
  color: #666;
  margin-bottom: 20px;
`;

function HomePage() {
    return (
        <HomePageContainer>
            <Title>EAT 54321</Title>
            <Subtitle>Fuel your body. Change your world.</Subtitle>
            <CallToAction>
                We&apos;re working hard to bring EAT 54321 to life. Our mission is to provide accessible, nutritious, and sustainable meal plans for everyone.
            </CallToAction>
            <CallToAction>
                Officially launching in <strong>2026</strong>. Stay tuned!
            </CallToAction>
               </HomePageContainer>
    );
}

export default HomePage;
